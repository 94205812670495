<template>
  <div>
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg12 sm12 xs12>
          <v-card>
            <v-card-text class="">
              <saida-form></saida-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import SaidaForm from "@/components/notaSaida/viewRetorno.vue"
export default {
  components: {
    SaidaForm
  },
  data: () => ({
    id: null
  }),
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
  }
}
</script>
