<template>
  <v-form ref="form">
    <v-toolbar color="pink" dark flat dense cad>
      <v-toolbar-title class="subheading">
        <div class="form-btn">
          Retorno {{ this.id }} - Saída {{ this.notaRetorno.idSaida }}
          <v-btn v-if="resumo" @click="visualizarNormal" outline>Normal</v-btn>
          <v-btn v-else @click="visualizarResumo" outline>Resumo</v-btn>
        </div>
      </v-toolbar-title>
    </v-toolbar>
    <v-layout row wrap justify-center>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Promotor de vendas"
            name="Promotor"
            placeholder="Promotor"
            v-model="notaRetorno.nomePromotor"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Praça"
            name="Praça"
            placeholder="Praça"
            v-model="notaRetorno.praca"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Data de saída"
            name="dataSaida"
            placeholder="Data de saída"
            v-model="notaRetorno.dataSaidaString"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Vendedor(a)"
            name="Vendedor(a)"
            placeholder="Vendedor(a)"
            v-model="notaRetorno.nomeVendedor"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="CPF"
            name="CPF"
            placeholder="CPF"
            v-model="notaRetorno.cpf"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Telefone"
            name="Telefone"
            placeholder="Telefone"
            v-model="notaRetorno.telefone"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Data de retorno"
            name="dataRetorno"
            placeholder="Data de retorno"
            v-model="notaRetorno.dataRetornoString"
            :readonly="true"
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div v-if="!resumo" class="d-flex">
          <v-data-table :headers="headers" :items="produtos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td>{{ props.item.codigoBarras }}</td>
              <td>{{ props.item.referencia }}</td>
              <td>{{ props.item.nomeProduto }}</td>
              <td>{{ props.item.precoVenda }}</td>
            </template>
          </v-data-table>
        </div>
        <div v-else class="d-flex">
          <v-data-table :headers="headersResumido" :items="produtosResumidos" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td>{{ props.item.referencia }}</td>
              <td>{{ props.item.nomeProduto }}</td>
              <td>{{ props.item.precoVenda }}</td>
              <td>{{ props.item.quantidadeVendida }}</td>
              <td>{{ props.item.quantidadeRetorno }}</td>
            </template>
          </v-data-table>
        </div>
      </v-flex>
      <v-flex lg12 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Total da venda"
            name="totalDaVenda"
            placeholder="Total da venda"
            v-model="pagamento.totalDaVenda"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Praça"
            name="Praça"
            placeholder="Praça"
            v-model="pagamento.percentualVendedora"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Valor a ser pago"
            name="valorASerPago"
            placeholder="Valor a ser pago"
            v-model="pagamento.valorASerPago"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Total pago"
            name="totalPago"
            placeholder="Total pago"
            v-model="pagamento.totalPago"
            :readonly="true"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Saldo"
            name="saldo"
            placeholder="Saldo"
            v-model="pagamento.saldo"
            :readonly="true"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-data-table :headers="headersPagamento" :items="pagamento.pagamentosItens" hide-actions class="elevation-0">
            <template v-slot:items="props">
              <td>{{ props.item.valorPago }}</td>
              <td>{{ props.item.formaPagamento }}</td>
              <td>{{ props.item.dataPagamentoString }}</td>
              <td>{{ props.item.dataInclusaoString }}</td>
            </template>
          </v-data-table>
        </div>
        <div class="form-btn">
          <router-link :to="{ name: 'saidaConsignada' }" class="nounderline">
            <v-btn outline>Voltar</v-btn>
          </router-link>
          <v-btn @click="excluir" outline>Excluir</v-btn>
        </div>
        <v-dialog v-model="dialog" max-width="800">
          <v-card>
            <v-card-title class="headline">Excluir Retorno</v-card-title>
            <v-card-text>Deseja realmente realizar a exclusão do retorno?</v-card-text>
            <v-card-text>Para visualizar itens excluídos selecione a opção visualizar nota excluída dentro do retorno.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                dark
                text
                @click="
                  dialog = false
                "
                >Cancelar</v-btn
              >
              <v-btn color="primary" dark text @click="excluirNotaRetorno()">Excluir</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
      <div>
        <progress-circular></progress-circular>
      </div>
      <v-snackbar :color="'error'" v-model="snackbar" :top="true">
        {{ text }}
        <v-btn dark text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
    </v-layout>
  </v-form>
</template>
<script>
import { serverBus } from "../../main"
import { ServicoRetorno } from "../../servicos/servicoRetorno"
import ProgressCircular from "../progressCircular/progressCircular"
const servicoRetorno = new ServicoRetorno()
export default {
  components: {
    ProgressCircular
  },
  data() {
    return {
      id: null,
      notaRetorno: "",
      produtos: [],
      produtosResumidos: [],
      pagamento: "",
      resumo: false,
      dialog: false,
      snackbar: false,
      text: "",
      headers: [
        { text: "Código de barras", align: "left", sortable: false, value: "codigoBarras" },
        { text: "Referência", align: "left", sortable: false, value: "referencia" },
        { text: "Produto", align: "left", sortable: false, value: "nomeProduto" },
        { text: "Preço de venda", align: "left", sortable: false, value: "precoVenda" }
      ],
      headersResumido: [
        { text: "Referência", align: "left", sortable: false, value: "referencia" },
        { text: "Produto", align: "left", sortable: false, value: "nomeProduto" },
        { text: "Valor vendido", align: "left", sortable: false, value: "precoVenda" },
        { text: "Quantidade vendida", align: "left", sortable: false, value: "quantidadeVendida" },
        { text: "Quantidade devolvida", align: "left", sortable: false, value: "quantidadeRetorno" }
      ],
      headersPagamento: [
        { text: "Valor pago", align: "left", sortable: false, value: "valorPago" },
        { text: "Forma de pagamento", align: "left", sortable: false, value: "formaPagamento" },
        { text: "Data do pagamento", align: "left", sortable: false, value: "dataPagamentoString" },
        { text: "Data de inclusão", align: "left", sortable: false, value: "dataInclusaoString" }
      ]
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
    }
    if (this.id) {
      servicoRetorno.buscarDetalhesRetorno(this.id).then(res => {
        this.notaRetorno = res.data
        this.produtos = res.data.produtos
        this.produtosResumidos = res.data.produtosResumidos
        this.pagamento = res.data.pagamento
      })
    }
  },
  methods: {
    excluir() {
      this.dialog = true
    },
    excluirNotaRetorno(){
      this.dialog = false
      this.abrirProgressCircular()
      servicoRetorno.excluirNotaRetorno(this.id)
        .then(res => {
          if (res.status === 200) {
            this.fecharProgressCircular()
            this.$router.push({ name: "view-saida", params: { id: this.notaRetorno.idSaida } })
          } 
        })
        .catch( res => 
        {
          if (res.response.status === 500) 
          {
            this.fecharProgressCircular()
            this.snackbar = true
            this.text = res.response.data 
          }
        })
    },
    abrirProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", true)
    },
    fecharProgressCircular() {
      serverBus.$emit("fecharAbrirDialog", false)
    },
    visualizarResumo() {
      this.resumo = true
    },
    visualizarNormal() {
      this.resumo = false
    }
  }
}
</script>
<style scoped>
a.nounderline:link {
  text-decoration: none;
}
</style>
